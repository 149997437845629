import { useEffect, useState } from 'react';

export const useQAShortcut = ({ onSwitchToQA, onSwitchToDirector, isQuestion }) => {
  // Track if a shortcut is currently being processed to prevent double-triggering
  const [isProcessingShortcut, setIsProcessingShortcut] = useState(false);
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      // Check for the keyboard combination
      if ((event.ctrlKey || event.metaKey) && event.altKey) {
        // Handle Q key for QA mode
        if (event.key.toLowerCase() === 'q') {
          // Prevent browser default behavior
          event.preventDefault();
          
          // Avoid double-triggering or processing while another shortcut is active
          if (isProcessingShortcut) return;
          
          // Check if the callback exists
          if (typeof onSwitchToQA === 'function') {
            setIsProcessingShortcut(true);
            
            // Ensure the event is only processed once and with a small delay
            // to allow any state updates to complete
            setTimeout(() => {
              onSwitchToQA();
              // Reset processing flag after a short delay
              setTimeout(() => setIsProcessingShortcut(false), 300);
            }, 50);
          }
        } 
        // Handle D key for Director mode
        else if (event.key.toLowerCase() === 'd') {
          event.preventDefault();
          
          if (isProcessingShortcut) return;
          
          if (typeof onSwitchToDirector === 'function') {
            setIsProcessingShortcut(true);
            
            setTimeout(() => {
              onSwitchToDirector();
              setTimeout(() => setIsProcessingShortcut(false), 300);
            }, 50);
          }
        }
      }
    };

    // Add global event listener at the window level to ensure it captures all events
    window.addEventListener('keydown', handleKeyPress, true);
    
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyPress, true);
    };
  }, [onSwitchToQA, onSwitchToDirector, isProcessingShortcut]);
}; 