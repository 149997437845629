import axios from 'axios';
import { AuthStateManager } from './authStateManager';

export const setupAxiosInterceptors = (onLogout) => {
  // Request interceptor to add auth token to all requests
  axios.interceptors.request.use(
    (config) => {
      // Get a fresh token for each request to avoid stale tokens
      const token = localStorage.getItem('authToken');
      
      // Only add the Authorization header if we have a valid token
      if (token && token !== 'null' && token !== 'undefined') {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        // Don't send authorization header at all if token is missing/invalid
        delete config.headers.Authorization;
      }
      
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor to handle auth errors
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        console.error('Authentication error:', error.response);
        
        // If we receive a 401, try to refresh the authentication state
        try {
          // Clear any stored authentication information
          await AuthStateManager.cleanupAuth();
          
          // Trigger logout or redirect
          if (onLogout && typeof onLogout === 'function') {
            onLogout();
          } else {
            window.location.href = '/';
          }
        } catch (cleanupError) {
          console.error('Error during auth cleanup:', cleanupError);
          window.location.href = '/';
        }
      }
      return Promise.reject(error);
    }
  );
}; 