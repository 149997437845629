import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { FaSignInAlt, FaUserCircle } from "react-icons/fa";
import { AuthStateManager } from '../utils/authStateManager';

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const [loginAttempted, setLoginAttempted] = useState(false);

  useEffect(() => {
    let mounted = true;

    const initializeAuth = async () => {
      if (!loginAttempted && !isAuthenticated && !isLoading) {
        await AuthStateManager.cleanupAuth();
      }
      
      if (mounted && isAuthenticated && !isLoading) {
        navigate('/home');
      }
    };

    initializeAuth();

    return () => {
      mounted = false;
    };
  }, [isAuthenticated, isLoading, navigate, loginAttempted]);

  const handleLogin = async () => {
    try {
      setLoginAttempted(true);
      await AuthStateManager.prepareForLogin();
      
      await loginWithRedirect({
        appState: { returnTo: '/home' },
        prompt: 'login',
        timeoutInSeconds: 60,
      });
    } catch (error) {
      console.error('Login failed:', error);
      setLoginAttempted(false);
      await AuthStateManager.cleanupAuth();
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-background text-text">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (isAuthenticated) {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background text-text">
      <div className="bg-background-light p-8 rounded-lg shadow-lg animate-glow-pulse">
        <h1 className="text-4xl font-bold mb-6 font-display text-center">Welcome to <span className="text-primary">ALICE</span></h1>
        <p className="mb-8 text-text-muted text-center">Revolutionizing productivity, one saved moment at a time.</p>
        <button
          onClick={handleLogin}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="w-full bg-primary text-text px-6 py-3 rounded-lg hover:bg-primary-dark transition-all duration-300 flex items-center justify-center font-semibold text-lg"
        >
          {isHovered ? <FaUserCircle className="mr-2" /> : <FaSignInAlt className="mr-2" />}
          Log In
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
