import { useEffect } from 'react';

export const useSaveShortcut = ({ onSave, isEnabled = true }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Command+S (Mac) or Control+S (Windows/Linux)
      if (isEnabled && (event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault(); // Prevent the browser's save dialog
        onSave();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onSave, isEnabled]);
};
