import React, { useEffect, useState, useRef } from "react";
import { FaClipboardList, FaUserCircle, FaBook, FaCopy, FaSpinner, FaCompass, FaSignOutAlt } from "react-icons/fa";
import Chat from "./Chat";
import { tokens } from "../features/tokenSlice";
import { getPlans } from "../features/getPlanSlice";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { viewResearch } from '../features/viewResearchSlice';
import { setNamePathSlice } from '../features/namePathSlice';
import { viewAnalysis } from '../features/viewAnalysisSlice';
import { chatMessages } from '../features/chatMessagesSlice';
import { questionMessages } from '../features/questionMessagesSlice';
import Navigator from './Navigator';
import Navbar from './Navbar';


const Home = () => {
  const { data, loading, initialized } = useSelector((state) => state.tokens);
  const { error: errorForPlans } = useSelector((state) => state.getPlans);
  const { isAuthenticated, logout } = useAuth0();
  const [retryCount, setRetryCount] = useState(0);
  const { data: logResponse } = useSelector((state) => state.logs) || {};
  const [isNavigatorOpen, setIsNavigatorOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const mainContentRef = useRef(null);
  const profileRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chatRef = useRef(null);

  useEffect(() => {
    const controller = new AbortController();
    
    const fetchTokens = async () => {
      if (isAuthenticated && (!initialized || !data?.theaissist)) {
        const success = await dispatch(tokens(true));
        if (!success && retryCount < 3) {
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
          }, 2000);
        }
      }
    };

    fetchTokens();

    const intervalId = setInterval(() => {
      if (isAuthenticated) {
        dispatch(tokens());
      }
    }, 30000); // Refresh every 30 seconds

    return () => {
      controller.abort();
      clearInterval(intervalId);
    };
  }, [dispatch, isAuthenticated, initialized, retryCount, data?.theaissist]);
  
  // Handle clicks outside profile dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.altKey) {
        switch (e.code) {
          case 'KeyV':
            e.preventDefault();
            const activeLog = (logResponse || [])
              .filter(log => !log.name.toLowerCase().includes('summary'))
              .find(log => log.active);

            if (activeLog) {
              dispatch(setNamePathSlice({
                name: activeLog.name,
                path: activeLog.path
              }));
              dispatch(viewResearch({ viewResearchPath: activeLog.path }));
              navigate('/viewResearch');
            }
            break;

          case 'KeyA':
            e.preventDefault();
            const activeLogForAnalysis = (logResponse || [])
              .filter(log => !log.name.toLowerCase().includes('summary'))
              .find(log => log.active);

            if (activeLogForAnalysis) {
              dispatch(viewAnalysis({ viewAnalysisPath: activeLogForAnalysis.path }));
              navigate('/viewAnalysis');
            }
            break;

          case 'KeyB':
            e.preventDefault();
            dispatch(getPlans());
            if (!errorForPlans) {
              navigate("/plans");
            }
            break;

          case 'KeyH':
            e.preventDefault();
            navigate('/help');
            break;

          case 'KeyQ':
            e.preventDefault();
            if (chatRef.current) {
              // Ensure we have a valid ref before using it
              try {
                // Dispatch this action first to ensure data is loaded
                dispatch(questionMessages()).then(() => {
                  // Wait a moment to ensure the state is updated before switching
                  setTimeout(() => {
                    if (chatRef.current) {
                      chatRef.current.switchToQA();
                    }
                  }, 100);
                });
              } catch (error) {
                console.error("Error handling Q shortcut:", error);
              }
            }
            break;

          case 'KeyD':
            e.preventDefault();
            if (chatRef.current) {
              // Ensure we have a valid ref before using it
              try {
                dispatch(chatMessages()).then(() => {
                  // Wait a moment to ensure the state is updated before switching
                  setTimeout(() => {
                    if (chatRef.current) {
                      chatRef.current.switchToCurrent();
                    }
                  }, 100);
                });
              } catch (error) {
                console.error("Error handling D shortcut:", error);
              }
            }
            break;

          case 'KeyN':
            e.preventDefault();
            setIsNavigatorOpen(prev => !prev);
            break;

          default:
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [dispatch, navigate, logResponse, errorForPlans]);

  const handleCopyToken = (token) => {
    navigator.clipboard.writeText(token).then(
      () => alert("Token copied to clipboard!"),
      (err) => alert("Failed to copy token:", err)
    );
  };

  return (
    <div className="flex h-screen bg-background text-text">
      {/* Left Panel - Fixed width */}
      <Navbar />
      
      {/* Main Content - Flexible width */}
      <div className="flex-1 flex flex-col min-w-0">
        <div 
          className={`flex-1 flex flex-col transition-[margin] duration-300 ease-in-out ${
            isNavigatorOpen ? 'mr-96' : ''
          }`}
          ref={mainContentRef}
        >
          <header className="bg-background-light p-4 shadow-md flex justify-between items-center">
            {/* Left section - Plans */}
            <div className="flex items-center space-x-4">
              <button
                className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
                onClick={() => {
                  dispatch(getPlans());
                  if (!errorForPlans) {
                    navigate("/plans");
                  }
                }}
              >
                <FaClipboardList className="mr-2" />
                Plans
              </button>
            </div>

            {/* Right section - Assistant, Help and User */}
            <div className="flex items-center space-x-4">
              <button
                className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
                onClick={() => setIsNavigatorOpen(true)}
              >
                <FaCompass className="mr-2" />
                Assistant
              </button>
              <button
                className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
                onClick={() => navigate('/help')}
              >
                <FaBook className="mr-2" />
                Help
              </button>
              
              {/* User profile dropdown */}
              <div className="relative" ref={profileRef}>
                <button 
                  className="flex items-center cursor-pointer focus:outline-none" 
                  onClick={() => setIsProfileOpen(!isProfileOpen)}
                  aria-expanded={isProfileOpen}
                  aria-haspopup="true"
                >
                  <FaUserCircle className="h-10 w-10 text-primary hover:text-primary-dark transition-colors duration-200" />
                </button>
                
                {/* Dropdown menu */}
                {isProfileOpen && (
                  <div className="absolute right-0 mt-2 w-72 bg-background-light rounded-lg shadow-xl z-10 py-2 border border-background-lighter">
                    <div className="px-4 py-3 border-b border-background-lighter">
                      <p className="text-sm font-medium text-text">User Profile</p>
                    </div>
                    <div className="px-4 py-3">
                      <p className="text-sm font-medium text-text mb-2">Credits</p>
                      {loading ? (
                        <div className="flex items-center text-text">
                          <FaSpinner className="animate-spin mr-2" />
                          Loading credits...
                        </div>
                      ) : !data || Object.keys(data).length === 0 ? (
                        <p className="text-sm text-text">Credits unavailable</p>
                      ) : (
                        <div className="space-y-2">
                          {Object.entries(data).map(([key, value]) => {
                            const formattedValue = Number(value).toFixed(2);
                            const displayName = key
                              .replace(/([A-Z])/g, ' $1')
                              .replace(/^./, str => str.toUpperCase())
                              .trim();
                              
                            return (
                              <div key={key} className="flex justify-between items-center">
                                <span className="text-sm text-text">{displayName}:</span>
                                <div className="flex items-center">
                                  <span className="text-sm font-medium text-text mr-2">{formattedValue}</span>
                                  <button
                                    className="text-text hover:text-primary focus:outline-none p-1"
                                    onClick={() => handleCopyToken(value)}
                                    aria-label={`Copy ${displayName} credits`}
                                  >
                                    <FaCopy className="h-4 w-4" />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div className="border-t border-background-lighter px-4 py-3">
                      <button
                        onClick={async () => {
                          try {
                            await logout({
                              logoutParams: {
                                returnTo: window.location.origin,
                              }
                            });
                            navigate('/');
                          } catch (error) {
                            console.error('Logout error:', error);
                          }
                        }}
                        className="w-full bg-red-600 hover:bg-red-700 text-white font-medium px-4 py-2 rounded-lg shadow-md flex items-center justify-center transition-colors duration-200"
                      >
                        <FaSignOutAlt className="mr-2" />
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </header>
          <main className="flex-1 p-4 overflow-hidden">
            <Chat ref={chatRef} />
          </main>
        </div>
      </div>

      {/* Assistant Panel - Fixed width, overlaid */}
      <Navigator 
        isOpen={isNavigatorOpen}
        onClose={() => setIsNavigatorOpen(false)}
      />
    </div>
  );
};

export default Home;