import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthStateManager } from '../utils/authStateManager';
import { setBaseUrl } from '../config/ApiConfig';
import { EnvironmentService } from '../services/EnvironmentService';

const AuthProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading, user } = useAuth0();
  const [emailSaved, setEmailSaved] = useState(false);

  // Force token refresh on component mount
  useEffect(() => {
    const refreshToken = async () => {
      if (isAuthenticated && !isLoading) {
        try {
          // Ensure we clear any stale tokens before getting a new one
          localStorage.removeItem('authToken');
          
          const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid profile email',
            ignoreCache: true  // Force a fresh token
          });
          
          localStorage.setItem('authToken', token);
        } catch (error) {
          console.error('Error refreshing token:', error);
          await AuthStateManager.cleanupAuth();
        }
      }
    };
    
    refreshToken();
  }, [isAuthenticated, isLoading, getAccessTokenSilently]);

  useEffect(() => {
    const initializeUser = async () => {
      if (isAuthenticated && !isLoading && user?.email && !emailSaved) {
        try {
          // Get the token we already refreshed or get a new one if needed
          let token = localStorage.getItem('authToken');
          
          if (!token) {
            token = await getAccessTokenSilently({
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: 'openid profile email'
            });
            localStorage.setItem('authToken', token);
          }
          
          // Set the base URL after we have the token
          setBaseUrl(user.email);  // Regular API routing based on email

          // Get auth-specific URL based on IP
          const authUrl = await EnvironmentService.getAuthUrl();
          
          try {
            const response = await fetch(`${authUrl}/save-email`, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });

            if (response.ok) {
              console.log('Email saved successfully');
              setEmailSaved(true);
            } else if (response.status === 401) {
              console.log('Session expired, refreshing authentication');
              // Force a token refresh on 401
              const newToken = await getAccessTokenSilently({ 
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: 'openid profile email',
                ignoreCache: true 
              });
              localStorage.setItem('authToken', newToken);
            } else {
              const errorData = await response.json();
              console.error('Failed to save email:', errorData);
            }
          } catch (error) {
            if (error.error === 'login_required') {
              console.log('Session expired, redirecting to login');
              await AuthStateManager.cleanupAuth();
            } else {
              console.error('Error saving email:', error);
            }
          }
        } catch (error) {
          console.error('Error during authentication:', error);
          await AuthStateManager.cleanupAuth();
        }
      }
    };

    initializeUser();
  }, [isAuthenticated, isLoading, getAccessTokenSilently, user, emailSaved]);

  return <>{children}</>;
};

export default AuthProvider;